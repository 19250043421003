import React from 'react';
import { Paper } from '@material-ui/core';
import SimpleDivider from './SimpleDivider';
import styled, {css} from 'styled-components';

const InnerWrapper = styled.div`
    padding: 10px;
    position: relative;
    /* height: calc(100vh - 200px); */
    height: 380px;
    overflow: hidden;

    ${props => props.color && css`
        background-color: ${props.color};
    `}

    ${props => props.image && css`
        background-image: url(${props.image});
        background-size: cover;
    `}
`

const ColorLayer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.4;

     ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

const Content = styled.div`
    /* position: absolute; */
    width: 90%;
    bottom: 10%;
    right: 0;
    padding: 20px;

    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: absolute;
    z-index: 2;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`

const ImageWrapper = styled.div`
    height: 50%;
    padding: 10px;
    position: relative;
    width: 50%;
    z-index: 1;

    top: 5%;
    left: -10px;

     ${props => props.url && css`
        background-image: url(${props.url});
        background-size: cover;
    `}

    &::after {
        content: '';
        position: absolute;
        border: 1px solid #333;
        width: calc(100% + 10px);
        height: calc(100% + 20px);
        left: 0px;
        top: -10px;
        border-left: 0;
    }
`

const SimpleCard = ({title, image, copy, color, fullBgImage=false}) => {
    return (
        <Paper elevation={3}>
            <InnerWrapper image={image && fullBgImage ? image : null}>
                {image && !fullBgImage && <ImageWrapper url={image} />}
                {color && image && fullBgImage && <ColorLayer color={color} />}
                <Content color={color}>
                    <SimpleDivider><h1>{title}</h1></SimpleDivider>
                    {copy && copy}
                </Content>
            </InnerWrapper>
        </Paper>
    );
};

export default SimpleCard;