import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Button from "@material-ui/core/Button"
import { Paper, Grid } from "@material-ui/core"
import SimpleCard from "../components/SimpleCard"
import { graphql } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Link to="/rooms">
          <SimpleCard
            title={
              <>
                Außer-
                <br />
                gewöhnliche
                <br />
                Locations
              </>
            }
            image="https://images.pexels.com/photos/2873951/pexels-photo-2873951.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            copy="Finden Sie außergewöhnliche Locations in der Neckaralb Region."
            color="#ffb6b9"
            fullBgImage
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to="/catering">
          <SimpleCard
            title={
              <>
                Außer-
                <br />
                gewöhnliches
                <br />
                Catering
              </>
            }
            image="https://images.pexels.com/photos/671956/pexels-photo-671956.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            copy="Für außergewöhnliches Catering müssen Sie nicht weit reisen."
            color="#fae3d9"
            fullBgImage
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to="/events">
          <SimpleCard
            title={
              <>
                Außer-
                <br />
                gewöhnliche
                <br />
                Events
              </>
            }
            image="https://images.pexels.com/photos/3274551/pexels-photo-3274551.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
            copy="Für außergewöhnliche Events müssen Sie nicht weit reisen."
            color="#bbded6"
            fullBgImage
          />
        </Link>
      </Grid>
      <Grid item xs={12} md={6}>
        <Link to="/equipment">
          <SimpleCard
            title={
              <>
                Tagungs-
                <br />
                equipment
              </>
            }
            image="https://images.pexels.com/photos/3782226/pexels-photo-3782226.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            copy="Tagungsequipment in der Region."
            color="#8ac6d1"
            fullBgImage
          />
        </Link>
      </Grid>
    </Grid>
  </Layout>
)

export const getData = graphql`
  {
    allFile(filter: { sourceInstanceName: { eq: "markdown" } }) {
      totalCount
      nodes {
        childMarkdownRemark {
          frontmatter {
            name
            room
          }
        }
      }
    }
  }
`

export default IndexPage
