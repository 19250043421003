import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
    display: table;
    margin: 16px 0;
    color: #333;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    background: 0 0;

    &::before,
    &::after  {
        position: relative;
        top: 50%;
        display: table-cell;
        width: 50%;
        border-top: 1px solid #333;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        content: '';
    }

    * {
        margin: 0;
        padding: 0 20px
    }
`

const SimpleDivider = ({children, style={}}) => {
    return (
        <Divider style={style}>
            {children}
        </Divider>
    );
};

export default SimpleDivider;